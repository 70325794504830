import React, { useEffect } from 'react';
//import _ from 'lodash';
import classNames from 'classnames';
import { Modal as BModal, ModalProps } from 'react-bootstrap';
//import './Modal.scss';

type Props = {
    /** Requires user response, you can't close it by clicking outside */
    isModal?: boolean,
    title?: React.ReactNode,
    /** The Modal buttons */
    footer?: React.ReactNode | React.ReactNode[],
    /** When set/truthy, this modal will auto-close after it was shown for this number of seconds (true = 3 second) */
    autoClose?: boolean | number,
    /** The Modal body */
    children?: React.ReactNode | React.ReactNode[],
} & ModalProps;

const Modal = ({
    isModal, title, footer, autoClose,  // custom props
    className, children, ...otherProps
}: Props) => {

    const DEFAULT_TIMEOUT_SECS = 3;

    useEffect(() => {
        if (!otherProps.show || !autoClose)
            return;

        const id = setTimeout(() => {
            otherProps.onHide && otherProps.onHide();
        }, (autoClose === true ? DEFAULT_TIMEOUT_SECS : autoClose) * 1000);

        return () => {
            clearTimeout(id);
        }
    }, [otherProps.show, autoClose]);


    return (
        <BModal
            backdrop={isModal ? 'static' : true}
            keyboard={!isModal}
            className={classNames(className)}
            {...otherProps}
        >
            <BModal.Header closeButton={!isModal}>
                <BModal.Title>{title}</BModal.Title>
            </BModal.Header>

            <BModal.Body>
                {children}
            </BModal.Body>

            {footer && (
                <BModal.Footer>
                    {footer}
                </BModal.Footer>
            )}
        </BModal>
    );
};

export default Modal;