import Login from "components/pages/Login";
import Home from "components/pages/Home";
import Logout from "components/pages/Logout";
import GiftCards from "components/pages/GiftCards";

const routes = {
    Login: {
        path: '/',
        element: <Login />,
    },
    Home: {
        path: '/home',
        element: <Home />,
    },

    GiftCards: {
        path: '/giftcards',
        element: <GiftCards />,
    },

    Logout: {
        path: '/logout',
        element: <Logout />,
    },
}

export default routes;