import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { logout } from "api/authApi";
import routes from "helpers/routes";
import ErrorText from "components/common/ErrorText";
import { useRqMutation } from "hooks/reactQueryHelper";

const Logout = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate, isLoading, isError, error } = useRqMutation<void>(logout, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            navigate(routes.Login.path);
        }
    });

    useEffect(() => {
        mutate();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {isLoading && <div>Logging out...</div>}
            {isError && <ErrorText text={'Error: ' + error} />}
        </div>
    );
}

export default Logout;