import React from 'react';
import { Container } from 'react-bootstrap';
import Navigation from './Navigation';

const Layout = (props) => {
    return (
        <div>
            <Navigation />
            <Container className='pt-4'>
                {props.children}
            </Container>
        </div>
    );
}

export default Layout;