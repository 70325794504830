import React, { useState, useEffect, useContext, ReactNode } from 'react';
import classNames from 'classnames';
import './ErrorText.scss';

type Props = {
    text?: string,
    className?: string,
    children?: any,
}

const ErrorText = ({
    text, className, children, ...otherProps
}: Props) => {

    return (
        <div className={classNames('error-text', className)} {...otherProps}>
            {text || children}
        </div>
    );
};

export default ErrorText;