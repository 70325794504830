import axios from "axios";

export async function login(values) {
    return axios.post('/auth/login', values);
}

export async function logout() {
    return axios.post('/auth/logout');
}

export async function fetchCurrentUser() {
    return axios.get('/auth/user');
}
