import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import routes from 'helpers/routes';
import { useUserData } from 'hooks/useUserData';
import './Navigation.scss';

const Navigation = () => {

    const NOT_IN_NAV = ['Login', 'Home'];

    const { data: user } = useUserData();

    return (
        <header>
            <Navbar bg="light" expand="md">
                <Container>
                    <Navbar.Brand as={Link} to={routes.Home.path} title='Home'>
                        <img className='logo' src='/images/logo.png' alt='Home' />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>

                            {user && Object.keys(routes).map(pageName => {
                                if (NOT_IN_NAV.includes(pageName)) return null;

                                const route = routes[pageName];
                                return <Nav.Link as={Link} to={route.path} key={pageName}>{pageName}</Nav.Link>;
                            })}

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Navigation;