import React, { useState } from 'react';
import _ from 'lodash';
import Moment from 'react-moment';
import { ArrowClockwise } from 'react-bootstrap-icons';

import { fetchCards } from 'api/giftcardApi';
import { useRQuery } from 'hooks/reactQueryHelper';
import AddEditGiftCardModal from 'components/scenes/AddEditGiftCardModal';
import { Button, ErrorText, Loading } from 'components/common';
import { Card } from 'components/models';
import GiftCard from '../scenes/GiftCard';
import './GiftCards.scss';


const GiftCards = () => {

    const [activeCardId, setActiveCardId] = useState<number | undefined>(undefined);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editingCard, setEditingCard] = useState<Card | undefined>(undefined);

    const { isLoading, isFetching, data: cards, isError, error, refetch, dataUpdatedAt } = useRQuery<Card[]>('giftcards', fetchCards, {
        cacheTime: 300 * 24 * 60 * 60 * 1000, // 300 days
        staleTime: 10 * 60 * 1000, // 10 min = fresh
    });

    function handleAddCard() {
        setEditingCard(undefined);
        setIsModalOpen(true);
    }

    function handleEdit(card: Card) {
        setEditingCard(card);
        setIsModalOpen(true);
    }

    return (
        <div className='gift-cards-page'>
            {isError && <ErrorText>Error refreshing cards: {error?.message}</ErrorText>}

            {isLoading && <Loading />}

            <div className='flex align-items-center'>
                <div className='flex-1'>
                    {dataUpdatedAt > 0 && (
                        <div className='text-xs text-gray-400' title='This would only be accurate if you were online during the fetch'>
                            Last refresh attempt: <Moment fromNow>{dataUpdatedAt}</Moment>
                        </div>
                    )}
                </div>
                <div className='flex-1'>
                    {!isLoading && isFetching && <div className='text-center'><Loading inline /></div>}
                    {!(isLoading || isFetching) && (
                        <a onClick={() => refetch()} className='block text-right'>
                            <ArrowClockwise title='Refresh now' />
                        </a>
                    )}
                </div>
            </div>



            {!isLoading && (
                <>
                    <div className='giftcards'>
                        {cards && cards.map((card, index) => (
                          <GiftCard key={card.id}
                                card={card}
                                activeCardId={activeCardId}
                                setActiveCardId={setActiveCardId}
                                onEdit={handleEdit}
                                onUpdated={refetch}
                            />
                        ))}
                    </div>

                    <Button onClick={handleAddCard}>Add GiftCard</Button>
                </>
            )}



            <AddEditGiftCardModal
                show={isModalOpen}
                onHide={(wasSaved) => {
                    setIsModalOpen(false)
                    if (wasSaved) refetch();
                }}
                card={editingCard && _.cloneDeep(editingCard)}
            />
        </div>
    );
};

export default GiftCards;