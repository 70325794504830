import React from 'react';
import classNames from 'classnames';
//import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { Col, Form, FormControlProps, InputGroup, Row } from 'react-bootstrap';
import layout from 'constants/layout';
import Textbox from './Textbox';

type Props = {
    name: string,
    label?: string,
    autoFocus?: boolean,
    className?: string,
} & FormControlProps;

const TextField = ({
    name, type, label, className, ...otherProps
}: Props) => {

    return (
        <Field id={name} name={name}>
            {({ field, form }) => {
                const error = form.errors[field.name];
                const isValid = !error;
                const isInvalid = form.touched[field.name] && !isValid;

                return (
                    <Textbox
                        className={className}
                        label={label}
                        {...field}
                        type={type}
                        isValid={form.touched[field.name] && isValid}
                        isInvalid={isInvalid}
                        error={error}
                        {...otherProps}
                    />

                    // <Form.Group as={Row} className='text-field'>
                    //     <Form.Label column sm={layout.LABEL_WIDTH}>{label}</Form.Label>
                    //     <Col sm={12 - layout.LABEL_WIDTH}>
                    //         <InputGroup>
                    //             <Form.Control
                    //                 {...field}
                    //                 type={type}
                    //                 isValid={form.touched[field.name] && isValid}
                    //                 isInvalid={isInvalid}
                    //                 feedback={error}
                    //                 {...otherProps}
                    //             />

                    //             <Form.Control.Feedback type="invalid">
                    //                 {error}
                    //             </Form.Control.Feedback>
                    //         </InputGroup>
                    //     </Col>
                    // </Form.Group>
                );
            }}
        </Field >
    );
};

// TextField.defaultProps = {
//     fullWidth: true
// }

export default TextField;