import React from 'react';
//import classNames from 'classnames';
import { Field } from 'formik';
import { Col, Form, FormSelectProps, InputGroup, Row } from 'react-bootstrap';
import layout from 'constants/layout';

export type SelectFieldProps = {
    name: string,
    label?: string,
    helperText?: string,
    className?: string,
} & FormSelectProps;

const SelectField = ({  // https://material-ui.com/components/selects/
    name, label, helperText, className, children, ...otherProps
}: SelectFieldProps) => {

    return (
        <Field name={name}>
            {({ field, form }) => {
                const error = form.errors[field.name];
                const isValid = !error;
                const isInvalid = form.touched[field.name] && !isValid;

                return (
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column sm={layout.LABEL_WIDTH}>{label}</Form.Label>
                        <Col sm={12 - layout.LABEL_WIDTH}>
                            <InputGroup>
                                <Form.Control
                                    {...field}
                                    as="select"
                                    type="select"
                                    isValid={form.touched[field.name] && isValid}
                                    isInvalid={isInvalid}
                                    feedback={error}
                                >
                                    {children}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                );


                // return (
                //     <FormControl
                //         className={classNames('text-field', className)}
                //         error={!!form.errors[name]}
                //     >
                //         {label && <InputLabel>{label}</InputLabel>}
                //         <Select variant='outlined'
                //             {...otherProps}
                //             value={field.value}
                //             onChange={(e) => form.setFieldValue(name, e.target.value)}
                //             />
                //         {(form.errors[name] || helperText) && (
                //             <FormHelperText>{form.errors[name] || helperText}</FormHelperText>
                //         )}
                //     </FormControl>
                // );
            }}
        </Field>
    );
};

export default SelectField;