import { ButtonProps } from 'react-bootstrap';
import { Button as BButton } from 'react-bootstrap';

type Props = {
    secondary?: boolean,
} & ButtonProps;

const Button = ({
    secondary, ...props
}: Props) => {
    return (
        <BButton
            variant={secondary ? 'secondary' : 'primary'}
            {...props}
        />
    );
}

export default Button;