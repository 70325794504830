import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Button, CheckboxField, ErrorText, Loading, TextField } from 'components/common';
import { useUserData } from 'hooks/useUserData';
import { login } from 'api/authApi';
import * as Yup from 'yup';
import routes from 'helpers/routes';
import { useRqMutation } from 'hooks/reactQueryHelper';
import './Login.scss';

const Login = () => {

    const [searchParams] = useSearchParams();

    const returnUrl = searchParams.get('returnUrl') || routes.GiftCards.path;

    const navigate = useNavigate();

    const { isLoading } = useUserData({
        onSuccess: () => navigate(returnUrl),  // Redirect if already logged in
    });
    const { isLoading: isSubmitting, mutate: mutateLogin, error } = useRqMutation(login, {
        onSuccess: () => navigate(returnUrl),  // Redirect upon successful login
    });

    async function handleSubmit(values) {
        //alert(JSON.stringify(vals, null, 2));

        mutateLogin(values);

        // axios.post('/auth/login', values)
        //     .then(() => setRedirect(true))
        //     .catch(err => {
        //         if (err.response?.data)
        //             setError(err.response.data);
        //         else
        //             setError('Failed: ' + err.code);
        //     });
    }

    return (<>
        <div className='login-page'>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    username: '',
                    password: '',
                    remember: false,
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().required('Username is required'),
                    password: Yup.string().required('Password is required'),
                })}
            >
                <Form><>
                    <TextField name='username' label='Username' />
                    <TextField name='password' label='Passcode' type='password' />
                    <CheckboxField name='remember' label='Remember me' />

                    <Button type="submit" disabled={isSubmitting}>Submit</Button>
                    {(isLoading || isSubmitting) && <Loading inline />}
                    {error && <ErrorText text={error.message} />}
                </></Form>
            </Formik>
        </div>
    </>);
};

export default Login;