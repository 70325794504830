import React from 'react';
import { useUserData } from 'hooks/useUserData';
//import './Home.scss';

type Props = {
    className?: string,
    children?: React.ReactNode,
}

const Home = ({
    className, children, ...otherProps
}: Props) => {

    const { isLoading, data: user } = useUserData();

    if (isLoading)
        return <h1>Loading...</h1>;

    return (
        <div className='home'>
            <h1>Welcome {user?.username}</h1>
        </div>
    );
};

export default Home;