import React from 'react';
import classNames from 'classnames';
import './Loading.scss';

type Props = {
    inline?: boolean,
    fill?: boolean,
}

const Loading = ({
    inline, fill, ...otherProps
}: Props) => {
    return (
        <div className={classNames('lds-circle', {
            'inline': inline,
            'fill': fill,
        })}>
            <div></div>
        </div >
    );
};

export default Loading;