// https://tkdodo.eu/blog/react-query-and-type-script
// See G:\CODE\VS Projects\Litul2\Litul2\ClientApp\node_modules\react-query\types\react\
//   useMutation.d.ts
//   useQuery.d.ts

import { MutationFunction, QueryFunction, QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from "axios";
import { ApiError } from "components/models";

export function useRQuery<T>(
    queryKey: QueryKey,
    queryFn: QueryFunction<AxiosResponse<T, any>, QueryKey>,
    options?: Omit<UseQueryOptions<AxiosResponse<T, any>, ApiError, T, QueryKey>, "queryKey" | "queryFn">) {

    return useQuery<AxiosResponse<T>, ApiError, T>(queryKey, queryFn, {
        select: (data) => data?.data,
        ...options
    });
}

export function useRqMutation<T = any>(
    mutationFn: MutationFunction<AxiosResponse, T>,
    options?: Omit<UseMutationOptions<AxiosResponse, ApiError, T>, 'mutationFn'>) {

    return useMutation<AxiosResponse, ApiError, T>(mutationFn, options);
}