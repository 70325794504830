import React from 'react';
import classNames from 'classnames';
import { Col, Form, InputGroup, Row, FormControlProps } from 'react-bootstrap';
import layout from 'constants/layout';
import './Textbox.scss';

type Props = {
    type?: string,
    label?: string,
    error?: string,
    autoFocus?: boolean,
    className?: string,
    children?: React.ReactNode,
} & FormControlProps;

const Textbox = ({
    label, error,
    className, children, ...otherProps
}: Props) => {

    return (
        <Form.Group as={Row} className={classNames('textbox', className)}>
            <Form.Label column sm={layout.LABEL_WIDTH}>{label}</Form.Label>
            <Col sm={12 - layout.LABEL_WIDTH}>
                <InputGroup>
                    <Form.Control
                        feedback={error}
                        {...otherProps}
                    />

                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </InputGroup>
            </Col>
        </Form.Group>
    );
};

export default Textbox;