import { useNavigate } from "react-router-dom";
import { fetchCurrentUser } from "api/authApi";
import routes from "helpers/routes";
import { useRQuery } from "./reactQueryHelper";
import { User } from "components/models";

type Params = {
    onSuccess?: () => void,
    onError?: (error: { message?: string }) => void,
};

/**
 * Fetch the current user, or redirects to login page if not logged in.
 */
export const useUserData = (params?: Params) => {
    const navigate = useNavigate();

    return useRQuery<User>('user', fetchCurrentUser, {
        onSuccess: params?.onSuccess,
        onError: (error: any) => {
            if (error?.code === "ERR_BAD_REQUEST" && window.location.pathname !== routes.Login.path)  // 401
                navigate(`${routes.Login.path}?returnUrl=${window.location.pathname}`);

            params?.onError && params?.onError(error);
        },
        cacheTime: 90 * 24 * 60 * 60 * 1000, // 90 days
        staleTime: 60 * 60 * 1000, // 1 hour = fresh
        refetchOnWindowFocus: false,
        keepPreviousData: false,
        retry: false,
    });
}