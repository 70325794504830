import axios from "axios";

// function get(url) {
//     const token = getCookieValue('jwt');
//     axios.get(url, {
//         headers: {
//             'Authorization': `Bearer ${token}`
//         }
//     });
// }

function postFiles(url: string, file?: any, data?: any) {
    if (data && Object.keys(data).includes('file')) // validate data
        throw new Error("Invalid data key: 'file'");

    let fdata = new FormData();

    fdata.append('file', file);

    // if (files?.length) {
    //     for (var i = 0; i < files.length; i++)
    //         fdata.append('file', files[i]);
    // }

    if (data)
        fdata.append('data', JSON.stringify(data));

    return axios.post(url, fdata);
}


function getCookieValue(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
}

export default {
    get: axios.get,
    post: axios.post,
    postFiles,
    getCookieValue,
}