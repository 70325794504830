export function byteArrayToBase64(buffer): string {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function formatCurrency(amount?: number): string | undefined | null {
    var formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return amount == null
        ? amount
        : formatter.format(amount); // eg. $2,500.00
}
