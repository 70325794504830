import React, { useState } from 'react';
import classNames from 'classnames';
import Moment from 'react-moment';
import moment from 'moment-timezone'
import 'moment-timezone';

import { formatCurrency } from 'helpers/utils';
import { Card } from 'components/models';
import { Button, ErrorText, Modal, Textbox } from 'components/common';
import { deleteBarcode, deleteGiftCard, updateBalance } from 'api/giftcardApi';
import { Trash3Fill } from 'react-bootstrap-icons';
import { useRqMutation } from 'hooks/reactQueryHelper';


type Props = {
    card: Card,
    activeCardId?: number,
    setActiveCardId: (id: number) => void,
    onEdit: (card: Card) => void,
    onUpdated: () => void,
}

const GiftCard = ({
    card, activeCardId, setActiveCardId, onEdit, onUpdated,
}: Props) => {

    const KMART_PREFIX = "9801935397100";

    const [showBalanceInput, setShowBalanceInput] = useState<boolean>(false);
    const [balance, setBalance] = useState<number>(card.lastBalance);

    const { mutate: mutateDelete, error: errorDelete } = useRqMutation<number>(
        deleteGiftCard,
        { onSuccess: onUpdated }
    );

    const { mutate: mutateDelBarcode, error: errorDelBarcode } = useRqMutation<number>(
        deleteBarcode,
        { onSuccess: onUpdated }
    );

    const { mutate: mutateUpdateBalance, isLoading: isLoadingUpdateBalance, error: errorUpdateBalance } =
        useRqMutation<Pick<Card, 'id' | 'lastBalance'>>(updateBalance, {
            onSuccess: () => {
                setShowBalanceInput(false);
                card.lastBalance = balance;
                onUpdated();
            }
        });

    const error = errorDelete || errorDelBarcode || errorUpdateBalance;


    function handleCheckBalance(card: Card) {
        let cardNo = card.cardNumber;
        let shortCardNo, url;

        if (card.type === 'ikea') {  // Ikea
            shortCardNo = cardNo.slice(cardNo.length - 13);
            url = "https://www.ikea.com/au/en/customer-service/gift-card-pub69dd64b0";
        }
        else {  // Coles / KMart
            shortCardNo = cardNo.length > 20 ? cardNo.slice(cardNo.length - 17) : cardNo;
            url = "https://secure.giftcards.com.au/retaillogin.aspx";
        }

        if (window.prompt("Memorise the PIN (" + card.pin + ") and copy this card number", shortCardNo) != null) {
            window.open(url, "_blank");

            setShowBalanceInput(true);
            setTimeout(() => {
                // Focus on the input field
                const input = document.getElementById('txtBalanceInput') as HTMLInputElement;
                input.focus();
                input.select();
            }, 500);
        }
    }

    function handleSaveBalance() {
        mutateUpdateBalance({ ...card, lastBalance: balance });
    }

    function handleDeleteBarcode(card: Card) {
        if (window.confirm("Delete the barcode permanently?")) {
            mutateDelBarcode(card.id);
        }
    }

    function handleDelete(card: Card) {
        if (window.confirm("Delete giftcard permanently?")) {
            mutateDelete(card.id);
        }
    }

    //#region ===== Render helpers =====
    function getBarcodeImageSrc(card: Card) {
        if (card.barcodeImage) {
            return "data:image/png;base64," + card.barcodeImage;
        }
        else if (card.type === "coles") {
            return "https://barcode.tec-it.com/barcode.ashx?data=" + card.cardNumber + "&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=192&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0";
        }
        else if (card.type === "kmart") {  // TODO Kmart barcode is actually different
            var completeCardNo = card.cardNumber?.startsWith(KMART_PREFIX) ? card.cardNumber : (KMART_PREFIX + card.cardNumber);
            return "https://barcode.tec-it.com/barcode.ashx?data=" + completeCardNo + "&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=192&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&qunit=Mm&quiet=0";
        }
        else if (card.type === "ikea") {
            return "https://barcode.tec-it.com/barcode.ashx?data=" + card.cardNumber + "&code=MobileQRCode&multiplebarcodes=false&translate-esc=false&dataattributekey_2=&dataattributeval_2=&dataattributekey_3=&dataattributeval_3=&dataattributekey_4=&dataattributeval_4=&dataattributekey_5=&dataattributeval_5=&digitallink=&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&codepage=Default&qunit=Mm&quiet=0&hidehrt=False&eclevel=M&dmsize=Default&download=true";
        }
    }

    /** Insert spaces to make it more readable */
    function formatNumber(cardNumber: string, cardType: string): string {
        switch (cardType) {
            case "coles":
                return insertSpaces(cardNumber, [7, 6, 4, 4, 4, 4]);
            case "kmart":
                return insertSpaces(cardNumber, [4, 4, 4, 4]);
            default:
                return cardNumber;
        }
    }
    function insertSpaces(text: string, steps: number[]): string {
        try {
            var result = "";
            var i = 0;

            steps.forEach(n => {
                if (i > 0)
                    result += " ";
                result += text.substr(i, n);
                i += n;
            });
            result += " " + text.substring(i);
            return result;
        }
        catch
        {
            return text;
        }
    }
    //#endregion

    return (<>
        <div
            className={classNames('giftcard', card.type, {
                'expired': card.lastBalance === 0 || (card.expire && moment(card.expire).isBefore(moment())),
                'expiring-soon': card.expire && moment(card.expire).isBefore(moment().add(1, 'years')),
                'active': activeCardId === card.id,
            })}
            onClick={() => setActiveCardId(card.id)}
        >
            <div className='logo keep-shown'></div>

            <div className='barcode'>
                <img src={getBarcodeImageSrc(card)} />
                {card.barcodeImage && (
                    <a onClick={() => handleDeleteBarcode(card)}><Trash3Fill title='Delete barcode' /></a>
                )}
            </div>

            <div className='cardNo'>{formatNumber(card.cardNumber, card.type)}</div>
            <div className='pin'>PIN: <b>{card.pin}</b></div>

            <div className='keep-shown'>
                Last balance: <b>{formatCurrency(card.lastBalance)}</b>
                {card.lastChecked &&
                    <> &ndash;&nbsp;
                        <span className='text-xs' title={moment.utc(card.lastChecked).format()}>
                            <Moment date={card.lastChecked} utc tz={moment.tz.guess()} fromNow />
                        </span>
                    </>
                }
                &nbsp; <a onClick={() => handleCheckBalance(card)}>Check</a>

                {card.expire && <div className='expiry'>Expire: <Moment format='D MMM YYYY'>{card.expire}</Moment></div>}

                {card.notes && <div className='notes'>Notes: {card.notes}</div>}
            </div>

            <a className='edit' onClick={() => onEdit(card)}>Edit</a>
            {card.lastBalance === 0 && <a className='delete' onClick={() => handleDelete(card)}>Delete</a>}
        </div>

        {error && <ErrorText text={error.message} />}



        <Modal title='Update balance' show={showBalanceInput} onHide={() => setShowBalanceInput(false)}>
            <Textbox
                id='txtBalanceInput'
                label='Balance'
                defaultValue={balance}
                onChange={(e) => {
                    let balance = e.target.value.trim();
                    if (balance.startsWith('$'))
                        balance = balance.substring(1);
                    setBalance(parseFloat(balance.trim()));
                }}
            />

            <Button
                onClick={handleSaveBalance}
                disabled={isLoadingUpdateBalance}
            >Save</Button>
        </Modal>
    </>);
};

export default GiftCard;