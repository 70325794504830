import React, { useRef } from 'react';
import classNames from 'classnames';
import { Field, FormikState } from 'formik';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import layout from 'constants/layout';

type Props = {
    name: string,
    label?: string,
    onChange?: (val: { newFiles: any[], previewUrl: string | undefined }) => void,
    className?: string,
}

const FileUploadField = ({
    name, label, onChange, className, ...otherProps
}: Props) => {

    const fileInputEl = useRef(null);
    const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(undefined);

    function handleChange(e, form) {
        var file = e.target.files?.[0];

        form.setFieldValue(name, file);
        setPreviewUrl(URL.createObjectURL(file));
        onChange && onChange(file);
    }

    return (
        <Field id={name} name={name}>
            {({ field, form }) => {
                const error = form.errors[field.name];
                const isValid = !error;
                const isInvalid = form.touched[field.name] && !isValid;

                return (
                    <Form.Group as={Row} className='file-upload-field mb-3'>
                        <Form.Label column sm={layout.LABEL_WIDTH}>{label}</Form.Label>
                        <Col sm={12 - layout.LABEL_WIDTH}>
                            <InputGroup>
                                <input
                                    ref={fileInputEl}
                                    //className='d-none'
                                    type='file'
                                    name={name}
                                    id={name}
                                    onChange={(e) => handleChange(e, form)}
                                    {...otherProps}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {error}
                                </Form.Control.Feedback>

                                {previewUrl && <img src={previewUrl} className='max-h-20' />}
                            </InputGroup>
                        </Col>
                    </Form.Group>
                );
            }}
        </Field>
    );
};

export default FileUploadField;