import React from 'react';
import AddEditGiftCard from './AddEditGiftCard';
import { Modal } from 'components/common';
import { Card } from 'components/models';

type Props = {
    card?: Card,
    show: boolean,
    onHide: (wasSaved: boolean) => void,
}

const AddEditGiftCardModal = ({
    card, onHide, ...otherProps
}: Props) => {

    return (
        <Modal
            title={card?.id ? 'Edit GiftCard' : 'Add GiftCard'}
            onHide={() => onHide(false)}
            {...otherProps}
        >
            <AddEditGiftCard
                card={card}
                onSuccess={() => onHide(true)}
            />
        </Modal>
    );
};

export default AddEditGiftCardModal;