import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/scenes/Layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import routes from 'helpers/routes';
import './App.scss'

const queryClient = new QueryClient();

function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Layout>
                    <Routes>
                        {Object.keys(routes).map((page, index) =>
                            <Route path={routes[page].path} element={routes[page].element} key={index} />
                        )}
                    </Routes>
                </Layout>

                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </div>
    );
}

export default App;