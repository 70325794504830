import React from 'react';
import { Field } from 'formik';
import { Form, FormCheckProps } from 'react-bootstrap';
import ErrorText from './ErrorText';
//import './CheckboxField.scss';

type Props = {
    name: string,
    required?: boolean,
    className?: string,
    children?: any,
} & Omit<FormCheckProps, 'type'>;

const CheckboxField = ({
    name, label, children, ...otherProps
}: Props) => {

    return (
        <Field type='checkbox' name={name}>
            {({ field, form }) => {
                const error = form.errors[field.name];
                const isValid = !error;
                const isInvalid = form.touched[field.name] && !isValid;

                return (<>
                    <Form.Check
                        type="checkbox"
                        name={name}
                        id={name}
                        label={children || label}
                        checked={!!field.value}
                        onChange={(e) => form.setFieldValue(name, e.target.checked)}
                        isValid={isValid}
                        isInvalid={isInvalid}
                        className='mb-3'
                        {...otherProps}
                    />

                    {error && <ErrorText>{error}</ErrorText>}
                </>);
            }}
        </Field>
    );
};

export default CheckboxField;