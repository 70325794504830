import React from 'react';
import { Form, Formik } from 'formik';
import { Button, ErrorText, FileUploadField, Loading, SelectField, TextField } from 'components/common';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import * as Yup from 'yup';
import { Card } from 'components/models';
import { AddEditCardParams, addOrEditGiftCard } from 'api/giftcardApi';
import { useRqMutation } from 'hooks/reactQueryHelper';


type Props = {
    card?: Card,
    onSuccess: () => void,
}

const AddEditGiftCard = ({
    card, onSuccess
}: Props) => {

    const MOMENT_INPUT_FORMAT = 'D/M/YYYY';
    const MOMENT_SEND_FORMAT = 'YYYY-MM-DD';

    const queryClient = useQueryClient();

    const { mutate, isLoading, error } = useRqMutation<AddEditCardParams>(addOrEditGiftCard, {
        onSuccess: () => {
            queryClient.invalidateQueries('giftcards');
            onSuccess && onSuccess();
        },
    });

    function handleSubmit(values: Partial<Card> & { barcodeFile?: any }) {
        mutate({
            ...values,
            cardNumber: values.cardNumber?.replace(/\s/g, ''),
            expire: values.expire
                ? moment(values.expire, MOMENT_INPUT_FORMAT).format(MOMENT_SEND_FORMAT)
                : undefined,
            isNew: !card?.id,
        });
    }


    const initialValues: Partial<Card> = card
        ? {
            ...card,
            expire: (card.expire?.length ?? 0) > 15  // ISO format
                ? moment(card.expire).format(MOMENT_INPUT_FORMAT)
                : card.expire,
        } : {
            type: 'coles',
            cardNumber: '',
            pin: '',
            expire: '',
            notes: '',
        };

    return (
        <div className='add-edit-giftcard'>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    type: Yup.string().required('Type is required'),
                    cardNumber: Yup.string().min(8, 'Too short').matches(/^[\d ]+$/, 'Must be numeric').required('Required'),
                })}
            >
                <Form><>
                    <SelectField name='type' label='Store'>
                        <option value='coles'>Coles</option>
                        <option value='kmart'>Kmart</option>
                        <option value='ikea'>Ikea</option>
                    </SelectField>
                    <TextField name='cardNumber' label='Card number' />
                    <TextField name='pin' label='PIN' />
                    <TextField name='expire' label='Expire' placeholder='dd/mm/yyyy' />
                    <TextField name='notes' label='Notes' />
                    <FileUploadField name='barcodeFile' label='Barcode' />

                    <Button type="submit" disabled={isLoading}>Save</Button>
                    {isLoading && <Loading inline />}
                    {error && <ErrorText text={error.message} />}
                </></Form>
            </Formik>

        </div>
    );
};

export default AddEditGiftCard;