import axios from "axios";
import http from "helpers/http";
import { Card } from "components/models";

export async function fetchCards() {
    return axios.get<Card[]>('/giftcard/list');
}

// export async function editGiftCard(card) {
//     return axios.post(`/giftcard/edit`, card);
// }

export async function updateBalance(card: Pick<Card, 'id' | 'lastBalance'>) {
    return axios.post(`/giftcard/updateBalance/${card.id}/${card.lastBalance}`);
}

export type AddEditCardParams = {
    barcodeFile?: any,
    isNew: boolean,
} & Partial<Omit<Card, 'barcodeImage'>>;

export async function addOrEditGiftCard(values: AddEditCardParams) {
    return http.postFiles(
        `/giftcard/${values.isNew ? 'add' : 'edit'}`,
        values.barcodeFile,
        values
    );
}

export async function deleteBarcode(cardId: number) {
    return axios.post(`/giftcard/deleteBarcode/${cardId}`);
}

export async function deleteGiftCard(cardId: number) {
    return axios.delete(`/giftcard/delete/${cardId}`);
}
